import React from "react";
import { navigate } from "gatsby-link";
import { sortable } from "react-sortable";

import Layout from "../../components/Layout";

import Sidenav from "../../components/dashboard/sidenav";
import DashboardBody from "../../components/dashboard/dashboardbody";

import "../../components/Styles/Custom.scss";
import "../../components/Styles/Dashboard.scss";
import axios from "axios";

import { uploadFile } from "react-s3";
import imageCompression from "browser-image-compression";
import { string } from "prop-types";
const config = {
  bucketName: "globelynx-booking-portal-experts-compressed",
  dirName: "public",
  region: "eu-west-1",
  accessKeyId: "AKIA6OWQUQ2FHYJBOXMC",
  secretAccessKey: "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",
};

let apiURL = "https://api.globelynx.com/api/";

// var items = [
//   "Gold",
//   "Crimson",
//   "Hotpink",
//   "Blueviolet",
//   "Cornflowerblue",
//   "Skyblue",
//   "Lightblue",
//   "Aquamarine",
//   "Burlywood"
// ]

class Item extends React.Component {
  render() {
    return (
      <div style={{ display: "inline-block" }} {...this.props}>
        {this.props.children}
      </div>
    );
  }
}

var SortableItem = sortable(Item);

// import ReactQuill from 'react-quill';

export default class HomeEditor extends React.Component {
  constructor(props) {
    super(props);

    if (typeof window !== "undefined") {
      var now = new Date();
      var timestamp_now = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      );

      // var timestamp_now = Math.floor(Date.now() / 1000);

      let token = localStorage.getItem("username");
      token = JSON.parse(token);
      if (
        localStorage.getItem("username") === null ||
        timestamp_now >= token.expires
      ) {
        navigate("/login");
      } else {
        navigate("/homepageeditor");
      }
    }

    this.state = {
      file: "",
      filelogo: "",
      imagePreviewUrl: "",
      imagePreviewUrlLogo: "",
      isLoading: false,
      carouselImg: [],

      items: [],
      bannerData: [],
    };

    this.changeProfile = this.changeProfile.bind(this);
    this.changeBanner = this.changeBanner.bind(this);
    this.getData = this.getData.bind(this);
    // this.onSort = this.onSort.bind(this)

    this.onSortItems = this.onSortItems.bind(this);
    this.getBanner = this.getBanner.bind(this);
    this.bannerStatusDropdown = this.bannerStatusDropdown.bind(this);
    this.updatebannerURL = this.updatebannerURL.bind(this);
  }

  updatebannerURL(e) {
    let self = this;

    self.setState({
      isLoading: true,
    });

    var bannerLink = document.querySelector("#bannerURL").value;
    var raw = JSON.stringify({
      bannerImage: self.state.bannerData[0].bannerImage,
      bannerLink: bannerLink,
      bannerStatus: self.state.bannerData[0].bannerStatus,
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    var bannerid =
      self.state.bannerData.length !== 0 ? self.state.bannerData[0]._id : "";

    fetch(apiURL + "banner/" + bannerid, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        self.getBanner();
        self.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        alert("Something went wrong.");
        this.setState({
          isLoading: false,
        });
      });
  }

  bannerStatusDropdown(e) {
    let self = this;

    self.setState({
      isLoading: true,
    });

    var raw = JSON.stringify({
      bannerImage: self.state.bannerData[0].bannerImage,
      bannerLink: self.state.bannerData[0].bannerLink,
      bannerStatus: e.target.value,
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    var bannerid =
      self.state.bannerData.length !== 0 ? self.state.bannerData[0]._id : "";

    fetch(apiURL + "banner/" + bannerid, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        self.getBanner();
        self.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        alert("Something went wrong.");
        this.setState({
          isLoading: false,
        });
      });
  }

  onSortItems(sortedList) {
    this.setState({
      carouselImg: sortedList,
    });

    sortedList.map((v, k) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        carouselName: v.carouselName,
        carouselContent: v.carouselContent,
        carouselPosition: k.toString(),
      });
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${apiURL}/carousel/${v._id}`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => {
          alert("Something went wrong.");
          this.setState({
            isLoading: false,
          });
        });
    });
  }
  // onSort(sortedList, dropEvent) {
  //   sortedList.map((v, k) => {
  //     var myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");
  //     var raw = JSON.stringify({ "carouselName": v.carouselName, "carouselContent": v.carouselContent, "carouselPosition": k.toString() });
  //     var requestOptions = {
  //       method: 'PUT',
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: 'follow'
  //     };

  //     fetch(`${apiURL}/carousel/${v._id}`, requestOptions)
  //       .then(response => response.text())
  //       .then(result => console.log(result))
  //       .catch(error => console.log('error', error));
  //   })
  // }

  deleteCarouselImage(id) {
    this.setState({
      isLoading: true,
    });

    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };

    fetch(`${apiURL}/carousel/${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.getData();
        alert("Image has been deleted");
      })
      .catch((error) => {
        alert("Something went wrong.");
        this.setState({
          isLoading: false,
        });
      });
  }

  getData() {
    this.setState({
      isLoading: true,
    });
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(apiURL + "carousel", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        result = result.sort((a, b) =>
          parseInt(a.carouselPosition) > parseInt(b.carouselPosition) ? 1 : -1
        );

        this.setState({
          carouselImg: result,
          isLoading: false,
        });
      })
      .catch((error) => {
        alert("Something went wrong.");
        this.setState({
          isLoading: false,
        });
      });
  }

  getBanner() {
    this.setState({
      isLoading: true,
    });

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(apiURL + "banner", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          bannerData: result,
          isLoading: false,
        });
      })
      .catch((error) => {
        alert("Something went wrong.");
        this.setState({
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      var timestamp_now = Math.floor(Date.now() / 1000);
      let token = localStorage.getItem("username");
      token = JSON.parse(token);

      if (
        localStorage.getItem("username") === null ||
        timestamp_now >= token.expires
      ) {
        navigate("/login");
      } else {
        this.getData();
        this.getBanner();
      }
    }
  }

  changeBanner(e) {
    var self = this;
    e.preventDefault();
    let reader = new FileReader();

    const imageFile = e.target.files[0];
    reader.readAsDataURL(imageFile);
    reader.onloadend = () => {
      this.setState({
        file: imageFile,
        imagePreviewUrl: reader.result,
      });
    };

    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    };
    imageCompression(imageFile, options)
      .then(function(compressedFile) {
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB

        uploadFile(compressedFile, config)
          .then((data) => {
            console.log(data);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
              bannerImage: imageFile.name,
              bannerLink: self.state.bannerData[0].bannerLink,
              bannerStatus: self.state.bannerData[0].bannerStatus,
            });

            var requestOptions = {
              method: "PUT",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            };

            var bannerid =
              self.state.bannerData.length !== 0
                ? self.state.bannerData[0]._id
                : "";

            fetch(apiURL + "banner/" + bannerid, requestOptions)
              .then((response) => response.text())
              .then((result) => {
                self.getBanner();
                alert("Image has been added.");
              })
              .catch((error) => {
                alert("Something went wrong.");
                self.setState({
                  isLoading: false,
                });
              });
          })
          .catch((err) => {
            alert("Something went wrong.");
            self.setState({
              isLoading: false,
            });
          });
      })
      .catch(function(error) {
        alert("Something went wrong.");
        self.setState({
          isLoading: false,
        });
      });

    // reader.readAsDataURL(file);
    // uploadFile(file, config)
    //   .then((data) => console.log(data))
    //   .catch((err) => console.log(err));
  }

  changeProfile(e) {
    var self = this;
    e.preventDefault();
    let reader = new FileReader();

    const imageFile = e.target.files[0];
    reader.readAsDataURL(imageFile);
    reader.onloadend = () => {
      this.setState({
        file: imageFile,
        imagePreviewUrl: reader.result,
      });
    };

    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    };
    imageCompression(imageFile, options)
      .then(function(compressedFile) {
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB

        uploadFile(compressedFile, config)
          .then((data) => {
            console.log(data);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
              carouselName: imageFile.name,
              carouselContent: imageFile.name,
              carouselPosition: self.state.carouselImg.length.toString(),
            });

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            };
            fetch(apiURL + "carousel", requestOptions)
              .then((response) => response.text())
              .then((result) => {
                self.getData();
                alert("Image has been added.");
              })
              .catch((error) => {
                alert("Something went wrong.");
                self.setState({
                  isLoading: false,
                });
              });
          })
          .catch((err) => {
            alert("Something went wrong.");
            self.setState({
              isLoading: false,
            });
          });
      })
      .catch(function(error) {
        alert("Something went wrong.");
        self.setState({
          isLoading: false,
        });
      });

    // reader.readAsDataURL(file);
    // uploadFile(file, config)
    //   .then((data) => console.log(data))
    //   .catch((err) => console.log(err));
  }
  render() {
    var listItems = this.state.carouselImg.map((item, i) => {
      return (
        <SortableItem
          key={i}
          onSortItems={this.onSortItems}
          items={this.state.carouselImg}
          sortId={i}
        >
          <i
            onClick={(e) => this.deleteCarouselImage(item._id)}
            style={{
              cursor: "pointer",
              color: "#d40000b8",
              paddingRight: 8,
              position: "absolute",
              fontSize: 20,
            }}
            className="fa fa-trash"
          />
          <img
            style={{
              // maxWidth: 130,
              // width: 'auto',
              // height: 100,
              // margin: '0 auto'

              // objectFit: 'cover',
              // width: '100%',
              // height: 130,
              border: "1px solid #ddd",
              pointerEvents: "none",
              objectFit: "contain",
              width: 250,
              height: 150,
              margin: "0 auto",
            }}
            src={`https://s3-eu-west-1.amazonaws.com/globelynx-booking-portal-experts-compressed/public/${item.carouselContent}`}
            // onError={(e) => {
            //   e.target.onerror = null;
            //   e.target.src = defaultProfile;
            // }}
          />
        </SortableItem>
      );
    });

    return (
      <Layout location="dashboard">
        {this.state.isLoading && (
          <div
            style={{
              width: "100%",
              background: "#00000066",
              height: "100vh",
              position: "fixed",
              zIndex: 1,
            }}
          >
            <i
              className="fa fa-spinner fa-spin"
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50 %)",
                textAlign: "center",
                width: "100%",
                color: "#fff",
                fontSize: 30,
              }}
            />{" "}
            Loading...
          </div>
        )}

        <Sidenav />
        {/* <DashboardBody /> */}
        <div className="main">
          <div className="container-fluid">
            <div className="row">
              <div className="six columns" style={{ textAlign: "center" }}>
                <div className="row">
                  <label id="InputFileUploaderbb">
                    {" "}
                    <i class="fa fa-upload" aria-hidden="true"></i> Upload
                    Carousel Image
                    <input
                      type="file"
                      id="profile_file"
                      onChange={this.changeProfile}
                      ref={(ref) => {
                        this.uploadInputProfile = ref;
                      }}
                    />
                  </label>
                </div>
                <ul className="sortable-list">{listItems}</ul>
              </div>

              <div className="six columns" style={{ textAlign: "center" }}>
                <div className="twelve columns" style={{ textAlign: "center" }}>
                  <label id="InputFileUploaderbb">
                    {" "}
                    <i class="fa fa-upload" aria-hidden="true"></i> Upload
                    Banner Image
                    <input
                      onChange={this.changeBanner}
                      ref={(ref) => {
                        this.uploadInputBanner = ref;
                      }}
                      type="file"
                      id="banner_file"
                    />
                  </label>
                  {this.state.bannerData.length !== 0 && (
                    <img
                      style={{
                        border: "1px solid #ddd",
                        pointerEvents: "none",
                        objectFit: "contain",
                        width: 250,
                        height: 150,
                        margin: "0 auto",
                      }}
                      src={`https://s3-eu-west-1.amazonaws.com/globelynx-booking-portal-experts-compressed/public/${this.state.bannerData[0].bannerImage}`}
                    />
                  )}
                </div>

                <div className="three columns" style={{ textAlign: "right" }}>
                  <select onChange={(e) => this.bannerStatusDropdown(e)}>
                    {this.state.bannerData.length !== 0 && (
                      <React.Fragment>
                        <option
                          value="open"
                          selected={
                            this.state.bannerData[0].bannerStatus == "open"
                          }
                        >
                          Enable
                        </option>
                        <option
                          value="close"
                          selected={
                            this.state.bannerData[0].bannerStatus == "close"
                          }
                        >
                          Disable
                        </option>
                      </React.Fragment>
                    )}
                  </select>
                </div>

                <div
                  className="nine columns"
                  style={{ textAlign: "center", display: "flex" }}
                >
                  {this.state.bannerData.length !== 0 && (
                    <input
                      className="u-full-width"
                      type="text"
                      value={this.state.bannerData[0].bannerLink}
                      style={{
                        padding: 24,
                      }}
                      id="bannerURL"
                    />
                  )}
                  <button onClick={(e) => this.updatebannerURL(e)}>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
